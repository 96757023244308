<template><div>hello</div></template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
};
</script>
<style scoped></style>
